import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './Context';

function Ordersuccess() {
    const { setCartData } = useContext(CartContext);

    useEffect(() => {
        // Clear the cart when the order is successful
        setCartData([]); // Set cart to empty array
        localStorage.removeItem('cartData'); // Optionally clear local storage
    }, [setCartData]);

    return (
        <section className="container mt-5" style={{marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
            <div className="row justify-content-center" style={{ marginTop: '350px',fontFamily: 'Oswald' }}>
                <div className="col-md-8">
                    <div className="card" style={{ fontFamily: 'Oswald', fontSize: '20px',height:'300px' }} >
                        <div className="card-body text-center" style={{ marginTop: '20px'}}>
                            <p>
                                <i className="fa fa-check-circle text-success fa-3x"></i>
                            </p>
                            <h3 className="text-success" style={{ fontFamily: 'Oswald', fontSize: '25px' }}>
                              Votre commande a été validée et un email de confirmation a été envoyé !
                            </h3>
                            <div style={{ marginBottom: '40px' }}></div>
                            <Link to="/" className="btn btn-dark btn-lg me-2" style={{ fontFamily: 'Oswald', borderRadius: '5px', fontSize: '18px', padding: '12px 25px' }}>
                            Accueil
                            </Link>
                            <Link to="/Stores/1/" className="btn btn-secondary btn-lg" style={{ fontFamily: 'Oswald', borderRadius: '5px', fontSize: '18px', padding: '12px 25px' }}>
                            Continuer vos achats
                            </Link>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '90px' }}></div>
            </div>
        </section>
    );
}

export default Ordersuccess;
