import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { CartContext } from './Components/Context';
import { useState } from 'react';
import './App.css';


import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import BackgroundAnimation from './Components/BackgroundAnimation';
import Home from './Components/Home';
import Products from './Components/Products';
import ProductDetail from './Components/ProductDetail';
import Categories from './Components/Categories';
import Subcategory from './Components/Subcategory';
import SubcategoryProducts from './Components/SubcategoryProducts';
import CategoryProduct from './Components/CategoryProduct';
import Cart from './Components/Cart';
import ConfirmOrder from './Components/ConfirmOrder';
import TagProducts from './Components/TagProducts';
import Wishlist from './Components/Wishlist.js';
import MovingBanner from './Components/MovingBanner'; 
import CrocoNutritionProducts from './Components/CrocoNutrition.jsx';
import CrocoWearProducts from './Components/CrocoTeam.js';
import StoreProducts from './Components/StoreProducts.jsx';
import OrderValidation from './Components/OrderValidation';
import OrderSuccess from './Components/OrderSuccess';
import ImageGallery from './Components/ImageGallery.jsx';
import Transformation from './Components/Transformation.jsx';
import Coaching from './Components/Coachings.jsx';
import Video from './Components/Videoo.jsx';
import CrocoTeam from './Components/CrocoTeam.js';




const YourMessengerBubble = () => {
    const [showLinks, setShowLinks] = useState(false); // State to toggle links visibility
    const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hover

    // Handle the Messenger bubble click (toggle the visibility of links)
    const handleBubbleClick = () => {
        setShowLinks(prevState => !prevState); // Toggle links visibility
    };

    // List of links for the Messenger bubble
    const links = [
        {
            name: "Croco Nutrition",
            url: 'https://www.facebook.com/messages/t/267874698411845',
        },
        {
            name: "Croco Wear",
            url: 'https://www.facebook.com/messages/t/742050152577583', // Replace with your URL
        },
        {
            name: "Croco Team",
            url: 'https://www.facebook.com/messages/t/366882246515869', // Replace with your URL
        },
    ];

    // Handle link click and open in a new tab
    const handleLinkClick = (url) => {
        if (url) {
            window.open(url, '_blank'); // Open link in a new tab
        }
    };

    return (
        <div>
            {/* Messenger Bubble */}
            <div
                className="messenger-bubble"
                style={{
                    position: 'fixed',
                    bottom: '40px',
                    right: '20px',
                    width: '60px', // Circular diameter
                    height: '60px',
                    backgroundImage: `url('/mess1.webp')`, // Path to image in public folder
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer',
                    backgroundColor: 'transparent', // Ensure no background color
                    padding: '0', // Remove any padding if present
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
                onClick={handleBubbleClick} // Handle bubble click to toggle links visibility
            />

            {/* Links dropdown */}
            {showLinks && (
                <div
                    style={{
                        position: 'fixed',
                        width: '150px',
                        fontFamily: 'Oswald',
                        bottom: '110px', // Position above the bubble
                        right: '30px',
                        borderRadius: '8px', // Border radius for the dropdown menu
                        backgroundColor: 'black', // Background for the links container
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 1000,
                    }}
                >
                    {links.map((link, index) => (
                        <a
                            key={link.name}
                            href="#!" // Prevent the default link navigation
                            onClick={() => handleLinkClick(link.url)} // Handle link click
                            style={{
                                borderRadius: '8px', // Border radius for individual links
                                display: 'block', // Make links stack vertically
                                padding: '10px',
                                textDecoration: 'none',
                                color: hoveredIndex === index ? 'white' : 'white', // Change text color on hover
                                backgroundColor: hoveredIndex === index ? '#009F6B' : 'black', // Change background on hover
                                transition: 'background 0.3s, color 0.3s', // Smooth transition
                                margin: '0px 0', // Add margin for spacing between links
                            }}
                            onMouseEnter={() => setHoveredIndex(index)} // Set hovered index on mouse enter
                            onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index on mouse leave
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};


const checkCart = localStorage.getItem('cartData');

function App() {
    const [cartData, setCartData] = useState(JSON.parse(checkCart));
    const location = useLocation();

    return (
        <CartContext.Provider value={{ cartData, setCartData }}>
                <>
                {location.pathname === '/' && <MovingBanner />}     
                    <MovingBanner></MovingBanner>          
                    <Navbar />
                    <BackgroundAnimation>

                        <Routes>
                            {/* Main Routes */}
                            
                            <Route path='/' element={<Home />} />
                            <Route path='/Products' element={<Products />} />
                            <Route path='/Categories' element={<Categories />} />
                            <Route path='/Category/:Category_slug/:Category_id' element={<CategoryProduct />} />
                            <Route path="/Subcategory/:subcategoryId/products" element={<SubcategoryProducts />} />
                            <Route path='/Subcategory' element={<Subcategory />} />
                            <Route path='/Products/:tag' element={<TagProducts />} />
                            <Route path='/Product/:Product_slug/:Product_id' element={<ProductDetail />} />
                            <Route path='Cart' element={<Cart />} />
                            <Route path='Confirm-Order' element={<ConfirmOrder />} />
                            <Route path="/stores/1/products" element={<CrocoNutritionProducts />} />
                            <Route path="/stores/3/products" element={<CrocoWearProducts />} />
                            <Route path="/stores/2/products" element={<CrocoTeam />} />
                            <Route path="/Stores/:storeId" element={<StoreProducts />} />
                            <Route path="/OrderValidation" element={<OrderValidation/>} />
                            <Route path="/order-success" element={<OrderSuccess/>}/>
                            <Route path='/Wishlist' element={<Wishlist />} />
                            <Route path="/ImageGallery" element={<ImageGallery/>}/>
                            <Route path="/Transformation" element={<Transformation/>}/>
                            <Route path="/Coaching" element={<Coaching/>}/>
                            <Route path="/Video" element={<Video/>}/>
                          
                        
                        </Routes>
                    </BackgroundAnimation>
                    {/* Render Footer only for main routes */}
                    {['/', '/Products', '/Categories', '/Category/:Category_slug/:Category_id', '/Subcategory/:subcategoryId/products', '/Subcategory', '/Products/:tag', '/Product/:Product_slug/:Product_id', '/Confirm-Order', '/NewOrders', '/Order/success', '/Order/failure'].includes(location.pathname) }
                    <Footer></Footer>

                     {/* Messenger Bubble */}
                     <YourMessengerBubble />
                </>
        </CartContext.Provider>
    );
}

export default App;