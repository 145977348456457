import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function App() {
  return (
    <MDBFooter bgColor='black' className='text-center text-lg-start text-muted'>
  <section className='p-4'style={{ marginTop: '35px', marginLeft: 'auto', marginRight: 'auto', width: '90%' }}>
    <MDBContainer className='text-center text-md-start' style={{ maxWidth: '2100px', marginTop: '35px',color:'white'}}>
      <MDBRow className='row'>
        {/* Updated CROCO LAND Column */}
        <MDBCol md='3' lg='2' xl='3' className='mx-auto d-flex flex-column' style={{ marginTop: '-70px' }}>
          <h4
            className='text-uppercase fw-bold mb-4'
            style={{ fontWeight: 'bolder', fontFamily: 'Oswald', textAlign: 'left', color: '#009F6B', fontSize: '1.8rem' }} // Updated styles
          >
            <Link className="navbar-brand" to="/">
              <img src="/CROCO-2.png" alt="Logo" className="navbar-logo" style={{ width: "220px", height: "auto" }} />
            </Link>
            
          </h4>
          <p>
          Bienvenue à Croco Land ! Ici, vous pouvez explorer les meilleurs produits de nutrition, vêtements et bien plus encore.  
          Nous vous proposons des articles de haute qualité adaptés à vos besoins.
          </p>
        </MDBCol>

        {/* Products Column */}
        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
          <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem',fontFamily: 'Oswald' }}> {/* Updated styles */}
            Services
          </h6>
          <p>
              <i class="fa-solid fa-truck-fast"></i> Livraison rapide en 48H
            </p>
            <p>
              <i class="fa-solid fa-file-circle-check"></i> 100% produits authentiques
            </p>
            <p>
              <i class="fa-solid fa-credit-card"></i> Payement à la livraison
          </p>

        </MDBCol>

        {/* Store Column */}
        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
          <h6 className='text-uppercase fw-bold mb-4' style={{ color: '	#009F6B', fontWeight: 'bolder', fontSize: '1.2rem',fontFamily: 'Oswald' }}> {/* Updated styles */}
            Nos Boutiques
          </h6>
          <p>
            <Link className="text-reset" to="/Stores/1" style={{ textDecoration: "none" }}>Croco Nutrition</Link>
          </p>
          <p>
            <Link className="text-reset" to="/Stores/2" style={{ textDecoration: "none" }}>Croco Wear</Link>
          </p>
          <p>
            <Link className="text-reset" to="/Stores/3" style={{ textDecoration: "none" }}>Croco Team</Link>
          </p>

        </MDBCol>

        {/* Contact Column */}
        <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-12'>
          <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem',fontFamily: 'Oswald' }}> {/* Updated styles */}
            Contact
          </h6>
          <p>
            <MDBIcon color='secondary' icon='home' className='me-2' />
            Charguia I , 2035
          </p>
          <p>
            <MDBIcon color='secondary' icon='envelope' className='me-3' />
            croconutrition@gmail.com
          </p>
          <p>
            <MDBIcon color='secondary' icon='phone' className='me-3' /> + 216 51 004 774
          </p>
        </MDBCol>

        {/* Localisation Column */}
        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-1'>
          <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem',fontFamily: 'Oswald' }}> {/* Updated styles */}
            Localisation
          </h6>
          <MDBCol color='secondary' icon='phone' className='me-7'>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12771.902051529169!2d10.2045625!3d36.8430625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd350f0e159295%3A0x353c15e6617a8438!2sCroco%20Nutrition!5e0!3m2!1sen!2stn!4v1726158170539!5m2!1sen!2stn"
              width="300" 
              height="200" 
              style={{ border: '0' }} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
              title="Map Location"
            ></iframe>
          </MDBCol>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </section>

  <div className='text-center p-4' style={{ backgroundColor: '#0E0E10', fontWeight: 'bold',fontFamily: 'Oswald',color:'#009F6B' }}>
    © 2024 Copyright: CROCO LAND
  </div>
</MDBFooter>

  );
}
