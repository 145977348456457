import React, { useContext, useState } from 'react';
import { CartContext } from './Context';
import { Link,useNavigate  } from 'react-router-dom';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
import './OrderValidation.css';


const baseUrl = CONSTURL; // Use the imported CONSTURL
function OrderValidation() {
    const { cartData, setCartData } = useContext(CartContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate(); 
    

    // Liste des villes de Tunisie avec leurs délégations
    const tunisiaCities = [
        { name: 'Béja', delegations: ['Béja Sud', 'Testour', 'Béja Nord', 'Nefza', 'Amdoun', 'Goubellat', 'Téboursouk', 'Medjez el Bab', 'Thibar'] },
        { name: 'Ben Arous', delegations: ['El Mourouj', 'Fouchana', 'Mornag', 'Mohamedia', 'Radès', 'Medina Jedida', 'Hammam Lif', 'Ben Arous', 'Ezzahra', 'Bou Mhel el-Bassatine', 'Hammam Chott', 'Mégrine'] },
        { name: 'Bizerte', delegations: ['Bizerte Nord', 'Menzel Bourguiba', 'Ras Jebel', 'Mateur', 'Bizerte Sud', 'Sejnane', 'Menzel Jemil', 'Joumine', 'Ghezala' , 'El Alia', 'Zarzouna', 'Tinja', 'Ghar El Melh', 'Utique' ] },
        { name: 'Gabès', delegations: ['El Hamma', 'Gabès Sud', 'Mareth', 'Gabès Médina', 'Gabès Ouest', 'Métouia', 'Ghannouch', 'Nouvelle Matmata', 'Menzel El Habib', 'Matmata'] },
        { name: 'Gafsa', delegations: ['Gafsa Sud', 'Métlaoui', 'Sened', 'El Ksar', 'Moularès', 'Redeyef', 'El Guettar', 'Belkhir', 'Mdhilla', 'Gafsa Nord', 'Sidi Aïch'] },
        { name: 'Jendouba', delegations: ['Jendouba Sud', 'Ghardimaou', 'Fernana', 'Tabarka', 'Jendouba Nord', 'Balta-Bou Aouane', 'Aïn Draham', 'Bou Salem' , 'Oued Meliz' ] },
        { name: 'Kairouan', delegations: ['Kairouan Nord', 'Kairouan Sud', 'Bou Hajla', 'Sbikha', 'Haffouz', 'Nasrallah', 'Oueslatia', 'Hajeb El Ayoun', 'Chebika', 'El Alâa', 'Echrarda'] },
        { name: 'Kasserine', delegations: ['Sbeïtla', 'Kasserine Nord', 'Fériana', 'Sbiba', 'Foussana', 'Thala', 'Majel Bel Abbès', 'Kasserine Sud', 'Ezzouhour', 'El Ayoun', 'Hassi El Ferid', 'Jedelienne', 'Haïdra'] },
        { name: 'Kébili', delegations: ['Kébili Sud', 'Kébili Nord', 'Souk Lahad', 'Douz Nord', 'Douz Sud', 'Faouar'] },
        { name: 'Ariana', delegations: ['Ariana Ville', 'La Soukra', 'Ettadhamen', 'Raoued', 'Mnihla' , 'Kalâat el-Andalous', 'Sidi Thabet' ] },

        { name: 'Manouba', delegations: ['Douar Hicher', 'Oued Ellil', 'La Manouba', 'Tebourba', 'Djedeida', 'Mornaguia', 'El Batan', 'Borj El Amri'] },

        { name: 'Mahdia', delegations: ['Mahdia', 'Ksour Essef', 'Essouassi', 'El Jem', 'Sidi Alouane', 'Bou Merdes', 'Chorbane', 'Chebba', 'Ouled Chamekh', 'Melloulèche', 'Hebira'] },

        { name: 'Médenine', delegations: ['Zarzis', ' Ben Gardane', 'Djerba - Houmt Souk', 'Djerba - Midoun', 'Médenine Nord', 'Médenine Sud', 'Beni Khedache', 'Djerba - Ajim', 'Sidi Makhlouf'] },
        { name: 'Monastir', delegations: ['Monastir', 'Moknine', 'Jemmal', 'Ksar Hellal', 'Téboulba', 'Ksibet el-Médiouni', 'Bembla', 'Zéramdine' , 'Sayada-Lamta-Bou Hajar', 'Sahline', 'Ouerdanine', 'Bekalta', 'Beni Hassen'] },

        { name: 'Nabeul', delegations: ['Hammamet', 'Korba ', 'Nabeul', 'Menzel Temime', 'Grombalia', 'Kélibia', 'Soliman', 'Dar Chaâbane El Fehri', 'El Haouaria', 'Béni Khiar', 'Béni Khalled', 'Menzel Bouzelfa', 'Bou Argoub', 'El Mida', 'Takelsa', 'Hammam Ghezèze'] },

        { name: 'Sfax ', delegations: ['Sfax Ville', ' Sfax Ouest', 'Sfax Sud', 'Sakiet Eddaïer', 'Sakiet Ezzit', 'Bir Ali Ben Khalifa', 'Thyna', 'Jebiniana', ' El Hencha', 'Agareb', 'Menzel Chaker', 'Mahrès', 'Skhira', 'El Amra', 'Graïba', ' Kerkennah'] },
        { name: 'Sidi Bouzid ', delegations: ['Sidi Bouzid Ouest', ' Regueb', 'Sidi Bouzid Est', 'Jilma', 'Bir El Hafey', 'Sidi Ali Ben Aoun', 'Menzel Bouzaiane', 'Mezzouna', ' Meknassy', 'Ouled Haffouz', 'Souk Jedid', 'Cebbala Ouled Asker', 'Makthar', 'Rouhia'] },
        { name: 'Siliana ', delegations: ['Siliana Sud', 'Siliana Nord ', 'El Krib', 'Bou Arada', 'Gaâfour', 'Kesra', 'Sidi Bou Rouis', 'Bargou', 'El Aroussa'] },
        { name: 'Sousse', delegations: ['Msaken', 'Sousse Riadh ', 'Sousse Jawhara', 'Sousse Jawhara', 'Kalâa Kebira', 'Sousse Sidi Abdelhamid', 'Enfida', 'Hammam Sousse', 'Sousse Médina', 'Kalâa Seghira', 'Akouda', 'Bouficha', 'Sidi Bou Ali', 'Kondar', 'Sidi El Hani', 'Hergla'] },
        { name: 'Tataouine ', delegations: ['Tataouine Nord', ' Tataouine Sud', 'Ghomrassen', 'Smâr', 'Remada', 'Bir Lahmar', 'Dehiba'] },
        { name: 'Tozeur', delegations: ['Tozeur', ' Degache', 'Nefta', 'Tameghza', 'Hazoua'] },
        { name: 'Tunis', delegations: ['Hraïria', ' El Kabaria', 'Sidi Hassine', 'La Marsa', 'Le Bardo', 'El Omrane supérieur', 'Le Kram', 'El Menzah', 'El Omrane', 'Ezzouhour', 'Bab El Bhar', 'Cité El Khadra', 'Séjoumi', 'El Ouardia', 'Bab Souika', 'Sidi El Béchir', 'La Goulette', 'Médina', 'Djebel Jelloud', 'Ettahrir', 'Carthage'] },
        { name: 'Zaghouan', delegations: ['El Fahs', 'Zaghouan ', 'Nadhour', 'Bir Mcherga', 'Zriba', 'Saouaf'] },
        { name: 'Kef ', delegations: ['Kef Est', ' Dahmani', 'Tajerouine', 'Kef Ouest', 'Nebeur', 'Sers', 'Sakiet Sidi Youssef', 'El Ksour', 'Kalaat Senan', 'Jérissa', 'Kalâat Khasba'] },

    ];

    // State pour les informations de livraison
    const [deliveryInfo, setDeliveryInfo] = useState({
        email: '',
        firstName: '',
        lastName: '',
        street: '',
        country: 'Tunisia',
        postalCode: '',
        city: '',
        delegation: '',
        phone: ''
    });

    const [delegations, setDelegations] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'city') {
            const selectedCity = tunisiaCities.find(city => city.name === value);
            setDelegations(selectedCity ? selectedCity.delegations : []);
            setDeliveryInfo({
                ...deliveryInfo,
                [name]: value,
                delegation: ''
            });
        } else {
            setDeliveryInfo({
                ...deliveryInfo,
                [name]: value
            });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('Informations de livraison:', deliveryInfo);
    };

    const handleNext = () => {
        if (currentIndex < cartData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleDeleteProduct = () => {
        const updatedCartData = cartData.filter((_, index) => index !== currentIndex);
        setCartData(updatedCartData);
        if (currentIndex >= updatedCartData.length && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const totalPrice = cartData.reduce((total, item) => {
        return total + (parseFloat(item.product.price) * item.qty);
    }, 0);

    function getFlavourColor(flavour) {
        switch (flavour.toLowerCase()) {
            case 'chocolat':
                return '#5b3c11';
            case 'vanille':
                return '#efef8f';
            case 'fraise':
                return 'pink';
            case 'cookies':
                return '#785e2f';
            case 'fraise_banane':
                return '#f88e55'; // Example of semi-pink and semi-yellow
            case 'caramel':
                return 'wheat';
            default:
                return 'gray'; // Default color for unknown flavours
        }
    }


    const isFormValid = () => {
        return (
            deliveryInfo.email &&
            deliveryInfo.firstName &&
            deliveryInfo.lastName &&
            deliveryInfo.street &&
            deliveryInfo.city &&
            deliveryInfo.delegation &&
            deliveryInfo.phone
        );
    };

    
    const handleValidateOrder = async (event) => {
        event.preventDefault();  // Prevent the form from submitting a GET request
        try {
            const response = await fetch( baseUrl+'validate_order/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: deliveryInfo.email,
                    firstName: deliveryInfo.firstName,
                    lastName: deliveryInfo.lastName,
                    street: deliveryInfo.street,
                    city: deliveryInfo.city,
                    delegation: deliveryInfo.delegation,
                    phone: deliveryInfo.phone,
                    orderDetails: cartData,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi de la commande');
            }
    
            const data = await response.json();
            navigate('/order-success');  // Redirect to the OrderSuccess page
        } catch (error) {
            console.error('Error:', error);
            alert('Une erreur s\'est produite lors de la validation de la commande.');
        }
    };
    
    

        

    return (
<div className="flex-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140vh' }}>
    <section className='container' style={{ marginTop: '50px' , display: 'flex', width: '80%' }}>
        {/* Gauche - Formulaire de livraison */}
        <div className="delivery-form" style={{ width: '50%', padding: '20px' }}>
            <h2 style={{ textAlign: 'center', color: 'black',fontFamily:'Oswald',textTransform:'uppercase' }}>Informations de Livraison</h2>
            <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto', textTransform: 'uppercase', fontSize: '17px' }}>
                <i className="fa-solid fa-circle-exclamation"></i> Veuillez remplir tous les champs du formulaire avant de valider la commande
                </p>

            <form onSubmit={handleFormSubmit}>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="email" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Email </label>
                            <input type="email"
                                id="email"
                                name="email"
                                value={deliveryInfo.email}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0', fontFamily: 'Roboto' }}
                                required />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="firstName" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Prénom </label>
                                <input type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={deliveryInfo.firstName}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px' }}
                                    required />
                            </div>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="lastName" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Nom </label>
                                <input type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={deliveryInfo.lastName}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px' }}
                                    required />
                            </div>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="street" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Adresse </label>
                            <input type="text"
                                id="street"
                                name="street"
                                value={deliveryInfo.street}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' }}
                                required />
                        </div>

                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="city" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Ville </label>
                            <select id="city"
                                name="city"
                                value={deliveryInfo.city}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' }}
                                required>
                                <option value="" style={{ color: 'black', fontFamily: 'Roboto' }}>Sélectionnez une ville</option>
                                {tunisiaCities.map((city, index) => (
                                    <option key={index} value={city.name}>{city.name}</option>
                                ))}
                            </select>
                        </div>
                        {delegations.length > 0 && (
                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="delegation" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Délégation </label>
                                <select id="delegation"
                                    name="delegation"
                                    value={deliveryInfo.delegation}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px', margin: '5px 0' }}
                                    required>
                                    <option value="" style={{ color: 'black', fontFamily: 'Roboto' }}>Sélectionnez une délégation</option>
                                    {delegations.map((delegation, index) => (
                                        <option key={index} value={delegation}>{delegation}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="phone" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Téléphone </label>
                            <input type="text"
                                id="phone"
                                name="phone"
                                value={deliveryInfo.phone}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' }}
                                required />
                        </div>
                        <button type="button" onClick={handleValidateOrder} disabled={!isFormValid()} style={{
                                padding: '10px 20px',
                                backgroundColor: '#006400',
                                color: 'white',
                                border: 'none',
                                fontWeight:'bolder',
                                fontFamily:'Roboto',
                                cursor: 'pointer',
                                textTransform:'uppercase',
                                width: '100%',
                                fontSize: '17px'
                            }}>
                            Valider votre commande
                        </button>
                    </form>
        </div>

        {/* Droite - Résumé du panier */}
        <div className="product-container" style={{ width: '50%', marginLeft: '20px', position: 'relative' }}>
    <h2 style={{ textAlign: 'center', color: 'black', fontFamily: 'Oswald', textTransform: 'uppercase' }}>Validation de la Commande</h2>
    <div className="table-responsive" style={{ width: '100%', margin: '0 auto', textAlign: 'left' }}>
        {cartData && cartData.length > 0 ? (
            <div style={{
                border: '1px solid black',
                padding: '20px',
                margin: '10px auto',
                backgroundColor: 'white',
                position: 'relative'
            }}>
                {/* Détails du produit */}
                {/* Supposant qu'un seul produit est affiché à la fois basé sur currentIndex */}
                <div style={{ position: 'relative' }}>
                    <button onClick={handleDeleteProduct} style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}>
                        <i className="fa-solid fa-trash-can" style={{ color: 'red', fontSize: '20px' }} title="Supprimer le produit"></i>
                    </button>
                </div>
                <div style={{ marginBottom: '50px' }}></div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img src={cartData[currentIndex].product.image}
                        alt={cartData[currentIndex].product.title}
                        style={{
                            width: '50%',
                            height: '200px',
                            objectFit: 'contain',
                            borderRadius: '8px'
                        }} />
                </div>
                <div style={{ marginBottom: '30px' }}></div>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Nom du Produit : <span>{cartData[currentIndex].product.title}</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Prix : <span>{(parseFloat(cartData[currentIndex].product.price) * cartData[currentIndex].qty).toFixed(2)} Dt</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Quantité : <span>{cartData[currentIndex].qty}</span>
                </p>

                {/* Display Flavour, Size, and Color */}
                <div>
    {cartData[currentIndex].selectedSize && (
        <p style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            margin: '10px 0', 
            fontWeight: 'bold', 
            color: 'black', 
            fontFamily: 'Roboto', 
            textTransform: 'uppercase'
        }}>
            <span>Taille :</span>
            <span>{cartData[currentIndex].selectedSize}</span>
        </p>
    )}
    {cartData[currentIndex].selectedColor && (
        <p style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            margin: '10px 0', 
            fontWeight: 'bold', 
            color: 'black', 
            fontFamily: 'Roboto', 
            textTransform: 'uppercase'
        }}>
            <span>Couleur :</span>
            <span>{cartData[currentIndex].selectedColor}</span>
        </p>
    )}
</div>


                {/* Conditionally show the Saveur section */}
                {!(cartData[currentIndex].selectedSize && cartData[currentIndex].selectedColor) && (
                    <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                        Saveur :
                        <span>
                            {cartData[currentIndex].selectedFlavour && cartData[currentIndex].selectedFlavour.length > 0 ? (
                                cartData[currentIndex].selectedFlavour.split(',').map((flavour, idx) => (
                                    <div key={idx} style={{
                                        display: 'inline-block',
                                        margin: '2px',
                                        position: 'relative',
                                    }}>
                                        <div style={{
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '50%',
                                            backgroundColor: getFlavourColor(flavour.trim()),
                                            display: 'inline-block',
                                            marginRight: '5px'
                                        }} />
                                        {flavour.trim()}
                                    </div>
                                ))
                            ) : (
                                <span>Aucune saveur sélectionnée</span>
                            )}
                        </span>
                    </p>
                )}

                <div style={{ position: 'absolute', bottom: '90px', left: '0', right: '0', display: 'flex', justifyContent: 'center', gap: '20px'}}>
                    <button onClick={handlePrevious}
                        disabled={currentIndex === 0}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '24px'
                        }}>
                        <i className="fa-solid fa-circle-arrow-left" style={{ color: currentIndex === 0 ? 'gray' : 'black' }}></i>
                    </button>
                    <button onClick={handleNext}
                        disabled={currentIndex === cartData.length - 1}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '24px'
                        }}>
                        <i className="fa-solid fa-circle-arrow-right" style={{ color: currentIndex === cartData.length - 1 ? 'gray' : 'black' }}></i>
                    </button>
                </div>

                <div style={{ marginTop: '90px', height: '2px', backgroundColor: 'black' }} />

                <p style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '15px', color: '#006400', fontSize: '20px', textTransform: 'uppercase' }}>
                    Total à Payer : <span style={{ color: 'black' }}>{totalPrice.toFixed(2)} Dt</span>
                </p>

            </div>
        ) : (
            <p>Aucun produit dans le panier.</p>
        )}
    </div>
</div>


    </section>
</div>
    );
}

export default OrderValidation;