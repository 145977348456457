import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { UserContext, CartContext } from './Context';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
const baseUrl = CONSTURL; // Use the imported CONSTURL
function Navbar() {
    const userContext = useContext(UserContext);
    const { cartData } = useContext(CartContext);
    const [isScrolled, setIsScrolled] = useState(false);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState(false); // Added state for categories dropdown
    const [openStores, setOpenStores] = useState(false);
    const [openMore, setOpenMore] = useState(false); // Added state for stores dropdown
    const location = useLocation();
    const searchRef = useRef(null); // Create a ref for the search area

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 50);
        };

        const fetchCategories = async () => {
            try {
                const response = await fetch(baseUrl+'Categories/');
                const data = await response.json();
                setCategories(data.results);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await fetch(baseUrl+'Products/');
                const data = await response.json();
                setProducts(data.results);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        window.addEventListener('scroll', handleScroll);
        fetchCategories();
        fetchProducts();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const filteredProducts = products.filter(product => 
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredProducts);
        }
    }, [searchTerm, products]);

    const cartItems = cartData ? cartData.length : 0;

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchResults([]); // Hide search results when clicking outside
            setSearchTerm(''); // Clear the search bar input
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnterCategories = () => setOpenCategories(true);
    const handleMouseLeaveCategories = () => setOpenCategories(false);
    const handleMouseEnterStores = () => setOpenStores(true);
    const handleMouseLeaveStores = () => setOpenStores(false);
    const handleMouseEnterMore = () => setOpenMore(true);
    const handleMouseLeaveMore = () =>  setOpenMore(false);

    return (
        <nav className={`navbar navbar-expand-lg ${location.pathname === '/' ? 'navbar-home' : 'navbar-other'} ${isScrolled ? 'scrolled' : ''}`} >
            <div className="container" style={{ width: '150%', maxWidth: '1600px'}}>
                <div className='new'>
                    <Link className="navbar-brand" to="/">
                        <div className="logo-wrapper5"> 
                            <img src="/CROCO-2.png" alt="Logo" className="navbar-logo" />
                        </div>
                    </Link>

                    <div ref={searchRef} className={`search-bar ${open ? 'show-search' : ''}`} style={{ position: 'relative', width: '100%' }}>
                    <form
                    onSubmit={(e) => {
                        e.preventDefault(); /* You may want to define handleSearch function */
                    }}
                    className="search-form"
                    style={{ display: 'flex', width: '180%' }}
                    >
                    <input
                        type="text"
                        placeholder="Recherchez votre produit"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                        flex: 1,
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        fontFamily: 'Oswald',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        }}
                    />
                    </form>


                        {searchResults.length > 0 && (
                            <div className="search-results" style={{
                                position: 'absolute',
                                top: '100%',
                                left: '0',
                                fontFamily: 'Roboto',
                                width: '155%', // Adjusted width
                                backgroundColor: 'black',
                                fontSize: '17px',
                                borderRadius: '5px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                zIndex: '50',
                                overflowY: 'auto',
                                marginTop: '5px',
                                textTransform: 'uppercase'
                            }}>
                                <ul style={{ listStyle: 'none', padding: '10px', margin: '0' }}>
                                    {searchResults.map(product => (
                                        <li 
                                            key={product.id} 
                                            style={{ display: 'flex', alignItems: 'center', padding: '10px 0', transition: 'background-color 0.3s' }}
                                            onMouseEnter={e => e.currentTarget.style.backgroundColor = '#289620'}
                                            onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                                        >
                                            <Link to={`/Product/undefined/${product.id}`} style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }}>
                                                <img 
                                                    src={product.image}
                                                    alt={product.title}
                                                    style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }}
                                                />
                                                <span>{product.title}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

              {/* Navbar Toggler */}
              <button
                className="navbar-toggler"
                type="button"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
              >
                <span className="fa fa-bars" style={{ color: 'white' }}></span>
              </button>
            </div>

                <div className={`collapse navbar-collapse ${open ? 'show' : ''}`}>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }} onClick={() => setOpen(false)}>Accueil</Link>
                        </li>


                        <li className="nav-item dropdown" onMouseEnter={handleMouseEnterCategories} onMouseLeave={handleMouseLeaveCategories}>
                           <li className="nav-item">
                            <Link className="nav-link" to="/Stores/1" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }} >Croco Nutrition</Link>
                            </li>
                            {openCategories && (
                                <ul className="dropdown-menu" style={{ display: 'block',width:'200%' }} onClick={() => setOpen(false)}>
                                      {/*<Link className="dropdown-item" to="#" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '17px' }}>
                                Catégories
                            </Link>*/}
                                    {categories.map(category => (
                                        <li key={category.id}>
                                            <Link className="dropdown-item" to={`/Category/${category.title}/${category.id}`} style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '19px' }}>
                                                {category.title}
                                            </Link>
                                            <ul className="dropdown-submenu">
                                                {category.subcategories.map(subcategory => (
                                                    <li key={subcategory.id}>
                                                        <Link className="dropdown-item" to={`/Subcategory/${subcategory.id}/products`} style={{ fontWeight: 'bold', fontFamily: 'Roboto', textTransform: 'uppercase', fontSize: '15px' }}>
                                                            {subcategory.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>

                     

                        <li className="nav-item dropdown" onMouseEnter={handleMouseEnterStores} onMouseLeave={handleMouseLeaveStores}>
                            
                        <ul className="navbar-nav" style={{ display: 'flex', gap: '20px' }}>
                       
                        <li className="nav-item">
                            <Link className="nav-link" to="/Stores/3" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }}>Croco Team</Link>
                        </li>

                        </ul>

                            {openStores && (
                            <ul className="dropdown-menu" style={{ display: 'block',width:'120%' }} onClick={() => setOpen(false)}>
                                
                                {/* <Link className="dropdown-item" to="/Stores/3" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '17px' }}>Croco Wear</Link>
                                <Link 
                                    className="dropdown-item" to="/Stores/2"  style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '17px' }}>  </Link>*/}
                                 <Link 
                                    className="dropdown-item"  to="/Coaching"  style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' }}>
                                  Coaching
                                    </Link> 

                                <Link className="dropdown-item" to="/Transformation" style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' }}>
                                Transformation
                                </Link>
                                
                               {/*  <Link className="dropdown-item" to="/CrocoEducation" style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' }}>
                                croco education</Link>*/}
                            </ul>
                            )}
                        </li>
                        <ul className="navbar-nav" style={{ display: 'flex'}}>
                            <li className="nav-item">
                            <Link className="nav-link" to="/Transformation" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }}onClick={() => setOpen(false)}>Transformation</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/Stores/2" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }}onClick={() => setOpen(false)}>Croco Wear</Link>
                            </li>
                        </ul>

                        <li className="nav-item dropdown" onMouseEnter={handleMouseEnterMore} onMouseLeave={handleMouseLeaveMore}>
                            
                            <ul className="navbar-nav" style={{ display: 'flex', gap: '20px' }}>
                            <li className="nav-item">
                                <Link className="nav-link"  style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '22px' }}>Plus</Link>
                            </li>
                            </ul>
                                {openMore && (
                               <ul className="dropdown-menu" style={{ display: 'block',width:'120%' }}onClick={() => setOpen(false)}>
                                  <Link 
                                        className="dropdown-item" 
                                        to="/video" 
                                        style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' }}>
                                           Video
                                        </Link>
                                </ul>
                                )}
                       </li>
            


                        <li className="nav-item">
                            <Link className="nav-link" to="/Cart"onClick={() => setOpen(false)}>
                                <span className="fa-stack has-badge" style={{ fontSize: '35px',fontFamily:'Oswald' }} data-count={cartItems}>
                                    <i className="fa fa-shopping-cart fa-stack-1x fa-inverse" style={{ fontSize: '30px' }}></i> 
                                    {/* Adjust the '30px' to the size you want */}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
