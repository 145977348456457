import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import { Link } from 'react-router-dom';
import { CartContext } from './Context';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

const CrocoWearProducts = ({ storeId }) => {
    const { cartData, setCartData } = useContext(CartContext);
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(false);

    const location = useLocation(); // Use the useLocation hook to get location object
   //const baseUrl = "http://127.0.0.1:8000/";
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    useEffect(() => {
        fetchData();
    }, [selectedCategory, selectedSubcategory, priceRange, currentPage]);

    const fetchData = (page = currentPage) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', page);
        setCurrentPage(page);

        let url = `${baseUrl}product_wear/?page=${page}&store=${storeId}`;

        if (selectedCategory) url += `&category=${selectedCategory}`;
        if (selectedSubcategory) url += `&subcategory=${selectedSubcategory}`;
        if (priceRange.min) url += `&price_min=${priceRange.min}`;
        if (priceRange.max) url += `&price_max=${priceRange.max}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setProducts(Array.isArray(data) ? data : []); // Ensure products is always an array
                setTotalPages(Math.ceil(data.length / 10)); // Adjust based on actual pagination logic
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
                setProducts([]); // Set products to an empty array if an error occurs
            });
    };

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page); // Fetch data for the selected page
    };

    // Add product to cart
    const cartAddButtonHandler = (product) => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: product.id,
                title: product.title,
                price: product.price,
                image: `${baseUrl}${product.image}`,
                color: product.color, // Include color
                size: product.size, // Include size
            },
            quantity: 1,
            user: {
                id: 1,
            },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(true);
    };
    

return (
    <section className="container products-page" style={{ marginTop: '250px', marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
        <h1 className="text-center text-black mb-5 fw-bold" style={{ fontFamily: 'Bebas Neue', fontSize: '50px', fontWeight: 'bolder' }}>
            Produits Croco Wear
        </h1>

        <div className="row justify-content-center">
            {products.map((product) => (
                <div className="col-md-3 col-sm-6 col-xs-12 mb-4" key={product.id}>
                    <div className="card d-flex flex-column" style={{ height: '500px', position: 'relative' }}>
                        {product.discount > 0 && (
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                left: '10px',
                                backgroundColor: '#cf000f', // Red background for visibility
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }}>
                                -{product.discount}%
                            </div>
                        )}
                        <Link to={`/product/${product.slug}/${product.id}`} className="link-wrapper">
                            <img src={`${baseUrl}${product.image}`} alt={product.title} className="card-title" style={{ height: '200px', objectFit: 'cover' }} />
                        </Link>
                        <div className="card-body-product d-flex flex-column align-items-center justify-content-between" style={{ padding: '8px', fontFamily: 'Impact', fontSize: '18px', color: 'black', textDecoration: 'none', flexGrow: 1 }}>
                            <h5 className="card-title">
                                <Link style={{ fontFamily: 'Roboto', fontWeight: 'bolder', color: 'black', textDecoration: 'none', fontSize: '22px' }} to={`/product/${product.slug}/${product.id}`}>
                                    {product.title}
                                </Link>
                            </h5>
                            <h5 className="card-title" style={{ fontFamily: 'Roboto', marginBottom: '20px', fontSize: '20px', color: '#26619C' }}>
                                Price: 
                                {product.discount > 0 ? (
                                    <>
                                        <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#26619C' }}>
                                            {product.price} DT
                                        </span>
                                        <span style={{ color: '#cf000f' }}>
                                            {product.price - (product.price * product.discount) / 100} DT
                                        </span>
                                    </>
                                ) : (
                                    <span>{product.price} DT</span>
                                )}
                            </h5>
                            <p style={{fontSize: '15px'}}>
    Couleur: {Array.isArray(product.color) && product.color.length > 0 ? 
        product.color.map((color, index) => (
            <span key={index} style={{
                display: 'inline-block',
                padding: '5px 10px',
                fontSize: '13px',
                margin: '0 2px',
                backgroundColor: '#fff0db', // Beige background for color box
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontWeight: 'bold',
                marginBottom: '5px', // Space between color boxes
            }}>
                {color.trim()}
            </span>
        ))
        : 'Non spécifiée'}
</p>

<p style={{fontSize: '15px'}}>
    Taille: {Array.isArray(product.size) && product.size.length > 0 ? 
        product.size.map((size, index) => (
            <span key={index} style={{
                display: 'inline-block',
                padding: '5px 10px',
                fontSize: '13px',
                margin: '0 2px',
                backgroundColor: '#fff0db', // Beige background for size box
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontWeight: 'bold',
                marginBottom: '5px', // Space between size boxes
            }}>
                {size.trim()}
            </span>
        ))
        : 'Non spécifiée'}
</p>




                        </div>
                        
                        <div className='card-footer-product d-flex flex-column align-items-center' style={{ marginBottom: '17px' }}>
                            <button
                                title="Add to cart"
                                style={{
                                    borderTop: '3px solid #0000',
                                    padding: '8px 20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '30px',
                                    backgroundColor: '#2ecc71',
                                    color: '#fff',
                                    border: 'none',
                                    fontFamily: 'Roboto',
                                    fontSize: '17px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                    width: '70%',
                                    height: '50px'
                                }}
                                onClick={() => cartAddButtonHandler(product)} // Pass product to the handler
                            >
                                <i className="fa-solid fa-cart-plus" style={{ marginRight: '8px' }}></i>
                                Ajouter au panier
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        {/* Pagination Controls */}
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center mt-4" style={{ gap: '10px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                border: '2px solid #000',
                                backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                color: currentPage === index + 1 ? '#fff' : '#000',
                                display: 'flex',
                                fontFamily: 'Oswald',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                            }}
                        >
                            {index + 1}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    </section>
);

    
    
};

export default CrocoWearProducts;


