import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext, UserContext } from './Context';
import axios from 'axios';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function OneProduct(props) {
 
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const { cartData, setCartData } = useContext(CartContext);
    const userContext = useContext(UserContext);
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(false);
    const [wishlistMessage, setWishlistMessage] = useState('');

    const cartAddButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: props.product.id,
                title: props.product.title,
                price: props.product.price,
                image: props.product.image,
            },
            quantity: 1,
            user: {
                id: 1,
            },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(true);
    };

    function saveInWishList() {
        const buyerId = localStorage.getItem('buyer_id');

        if (!buyerId) {
            console.error('Buyer ID not found in localStorage');
            return;
        }

        const formData = new FormData();
        formData.append('Buyer', buyerId);
        formData.append('product', props.product.id);

        axios
            .post(baseUrl + '/Wishlist/', formData)
            .then(function (response) {
                setWishlistMessage('Product added to wishlist successfully');
                setTimeout(() => {
                    setWishlistMessage(''); // Clear the message after 3 seconds
                }, 3000);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // Calculate the discounted price if applicable
    const discount = props.product.discount || 0; // Assuming the discount is a percentage
    const originalPrice = props.product.price;
    const discountedPrice = discount > 0 ? (originalPrice - (originalPrice * discount / 100)).toFixed(2) : originalPrice;

    return (
        <div className='col-12 col-md-4 col-sm-2 mb-4'>
            <div className="card d-flex flex-column" style={{ height: '400px', position: 'relative' }}> {/* Set relative positioning */}
                {discount > 0 && (
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: '#cf000f', // Red background for visibility
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>
                        -{discount}%
                    </div>
                )}
                <Link to={`/product/${props.product.slug}/${props.product.id}`} className="link-wrapper">
                    <img src={props.product.image} className="card-title" alt="..." style={{ height: '200px', objectFit: 'cover' }} />
                </Link>
                <div className="card-body-product d-flex flex-column align-items-center justify-content-between" style={{ padding: '8px', fontFamily: 'Impact', fontSize: '18px', color: 'black', textDecoration: 'none', flexGrow: 1 }}>
                    <h5 className="card-title">
                        <Link style={{ fontFamily: 'Roboto', fontWeight: 'bolder', color: 'black', textDecoration: 'none', fontSize: '22px', }} to={`/product/${props.product.slug}/${props.product.id}`}>
                            {props.product.title}
                        </Link>
                    </h5>
                    <h5 className="card-title" style={{ fontFamily: 'Roboto', marginBottom: '20px', fontSize: '20px',color: '#26619C'  }}>
                        Price: 
                        {discount > 0 ? (
                            <>
                                <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#26619C' }}>
                                    {originalPrice} DT
                                </span>
                                <span style={{ color: '#cf000f' }}>
                                    {discountedPrice} DT
                                </span>
                            </>
                        ) : (
                            <span>{originalPrice} DT</span>
                        )}
                    </h5>
                </div>
                <div className='card-footer-product d-flex flex-column align-items-center' style={{ marginBottom: '17px' }}>
                    <button
                        title='Add to cart'
                        style={{
                            borderTop: '3px solid #0000',
                            padding: '8px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '30px',
                            backgroundColor: '#2ecc71',
                            color: '#fff',
                            border: 'none',
                            fontFamily: 'Roboto',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                            width: '70%',
                            height: '50px'
                        }}
                        onClick={cartAddButtonHandler}
                    >
                        <i className="fa-solid fa-cart-plus" style={{ marginRight: '8px' }}></i>Ajouter au panier
                    </button>
                </div>

                {wishlistMessage && (
                    <div className="alert alert-success mt-2" role="alert" style={{ fontFamily: 'Trebuchet MS', textAlign: 'center', borderRadius: '0' }}>
                        {wishlistMessage}
                    </div>
                )}
            </div>
        </div>
    );
}

export default OneProduct;
