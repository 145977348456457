import React, { useEffect, useState } from 'react';
import './BackgroundAnimation.css';

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        fetch('http://localhost:8000/videos/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => setVideos(data))
            .catch((error) => console.error("Fetching error:", error));
    }, []);

    
   return (

    <div style={{ color: '#2ecc71', textAlign: 'center', padding: '150px' , marginTop: '150px' }}>
        <h2>MA CHAÎNE YOUTUBE</h2>
        <p>Sur YouTube, je laisse à votre disposition un panel de vidéos d’entraînements réalisées en compagnie de mes athlètes. Ce sera pour vous l'occasion d'y découvrir des conseils avisés et des exercices ciblés mais plus que tout, l'énergie qui nous anime. Laissez-la vous imprégner afin qu'elle vous accompagne dans votre transformation physique.</p>
        <p>Chaque vidéo offre une perspective différente pour répondre à vos attentes, dans cette quête de : "LA MEILLEURE VERSION DE VOUS MÊME".</p>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
            <div style={{ width: '2000px', height: '800px', overflow: 'hidden', borderRadius: '8px', border: '1px solid #ddd' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/jwi0zoe6Rxs?start=168" // Lien intégré avec temps de départ
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>

        <a 
            href="https://www.youtube.com/@azermhamdi3670"  // Lien vers la chaîne YouTube
            target="_blank" 
            rel="noopener noreferrer"
            style={{ 
                display: 'inline-block', 
                padding: '10px 20px', 
                backgroundColor: '#00b894', 
                color: '#fff', 
                borderRadius: '5px', 
                textDecoration: 'none',
                fontWeight: 'bold' 
            }}
        >
            DÉCOUVRIR MA CHAÎNE YOUTUBE
        </a>
    </div>
    
);

};

export default VideoGallery;
