import React, { useEffect, useState } from 'react';
import './BackgroundAnimation.css';

const TransformationGallery = () => {
    const [transformations, setTransformations] = useState([]);

    useEffect(() => {
        fetch('http://localhost:8000/transformations/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => setTransformations(data))
            .catch((error) => console.error("Fetching error:", error));
    }, []);



    return (

        
     
        <div
            style={{
                
                backgroundImage: `url('/covercrocoteam.png')`, // Replace with your background image URL
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                minHeight: '750px',
                padding: '80px',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <div
                style={{
                    marginTop: '210px',
                    maxWidth: '1500px',
                    width: '110%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Responsive grid
                    gap: '20px', // Spacing between items
                    padding: '10px',
                }}
            >
                {transformations.map((transformation, index) => (
                    <div
                        key={index}
                        style={{
                            background: 'rgba(255, 255, 255, 0.9)', // Card background with slight transparency
                            borderRadius: '12px',
                            overflow: 'hidden',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        }}
                        onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = 'scale(1.05)')
                        }
                        onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = 'scale(1)')
                        }
                    >
                        <img
                            src={transformation.image_url}
                            alt={transformation.title}
                            style={{
                                width: '100%',
                                height: '350px',
                                objectFit: 'cover',
                            }}
                            onError={(e) => (e.target.style.display = 'none')}
                        />
                        <div style={{ padding: '15px', textAlign: 'center' }}>
                            <h3
                                style={{
                                    fontSize: '1.2rem',
                                    margin: '10px 0',
                                    color: '#333',
                                }}
                            >
                                {transformation.title}
                            </h3>
                            <p
                                style={{
                                    fontSize: '1rem',
                                    color: '#555',
                                    lineHeight: '1.5',
                                }}
                            >
                                {transformation.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default TransformationGallery;
