import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CartContext } from './Context'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
const baseUrl = CONSTURL; // Use the imported CONSTURL
const randomImages = [
   
        '/crconut1.jpg',
        '/crocoteam1.jpg',
        '/crocoteam2.jpg',
        '/crocowear.jpg'
       
   
];

function Home() {
    const [stores, setStores] = useState([]);
    const [products, setProducts] = useState([]);
    const { setCartData } = useContext(CartContext); 
    const [wishlistData, setWishlistData] = useState([]);

    useEffect(() => {
        const fetchStores = fetch(baseUrl+'Stores/');
        const fetchProducts = fetch(baseUrl+'Products/?fetch_limit=8');
    
        Promise.all([fetchStores, fetchProducts])
            .then(async ([storesResponse, productsResponse]) => {
                const storesData = await storesResponse.json();
                const productsData = await productsResponse.json();
    
                // Optionally correct any reversed IDs directly here
                const correctedStoresData = storesData.results.map(store => {
                    if (store.name === 'Croco Team') return { ...store, id: 3 };
                    if (store.name === 'Croco Wear') return { ...store, id: 2 };
                    return store;
                });
    
                setStores(correctedStoresData);
                setProducts(productsData.results || []);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [setCartData]);
    

    const cartAddButtonHandler = (product) => {
        const previousCart = JSON.parse(localStorage.getItem('cartData')) || [];
        const cartData = {
            product: {
                id: product.id,
                title: product.title,
                price: product.price,
                image: product.image,
            },
            quantity: 1,
            user: { id: 1 },
        };
        const updatedCartJson = [...previousCart, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
    };

    const addToWishlistHandler = (product) => {
        const updatedWishlist = [...wishlistData, product];
        setWishlistData(updatedWishlist);
        localStorage.setItem('wishlistData', JSON.stringify(updatedWishlist));
    };

    const swiperSettings = {
        spaceBetween: 30,
        slidesPerView: 1.5, // Full-width on mobile
        loop: true,
        breakpoints: {
            640: { slidesPerView: 1.5 }, // Full-width on small screens
            768: { slidesPerView: 2 }, // Two products on medium screens
            1024: { slidesPerView: 5 }, // Four products on large screens
        },
    };

    const storeSwiperSettings = {
        spaceBetween: 0,
        slidesPerView: 1.5, // Full-width for stores on mobile
        loop: false,
        breakpoints: {
            640: { slidesPerView: 1.5}, // Full-width on mobile
            768: { slidesPerView: 2 }, // Two stores on medium screens
            1024: { slidesPerView: 3 }, // Three stores on large screens
        },
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const imageCarouselImages = [
        '/x.png',
        '/x2.png',
        '/x3.png',
        '/7.png',
        '/9.png',
        '/x6.jpg',
        '/444.webp',
        '/101.png',
        '/x9.webp',
        '/x10.png'
    ];

    const handleBubbleClick = () => {
        // Open the specific Messenger conversation link in a new tab
        window.open(
            'https://www.messenger.com/t/267874698411845/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=6946816&recurring_notification=0', 
            '_blank'
        );
    };


    return (
        <>
        <div style={{ marginTop: '200px', display: 'flex', justifyContent: 'center' }}>
            <main className="container-fluid p-0">
                
                {/* Stores Section in Swiper */}
                <div className="Stores-Section">
                    <h1 className="text-center my-5 text-black" style={{ fontSize: '4rem', fontWeight: 'bold', fontFamily: 'Bebas Neue', color: 'black' }}>Nos boutiques</h1>
                    <div className="row justify-content-center mr-0" 
                        style={{ 
                            backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/background/20210814/pngtree-business-gradient-geometric-gray-square-abstract-simple-gray-background-image_759189.jpg')`, 
                            backgroundSize: 'cover', 
                            backgroundPosition: 'center', 
                            padding: '20px',
                            marginTop: '70px'
                        }}
                    >
                        <Swiper {...storeSwiperSettings}>
                            {stores.map(store => (
                                <SwiperSlide key={store.id} className="text-center mb-4">
                                    <Link to={`/Stores/${store.id}`}>
                                        <img
                                            src={store.image || '/default-store.jpg'}
                                            alt={store.id}
                                            className="rounded-circle img-fluid"
                                            style={{ width: '60%', height: 'auto' }}  
                                        />
                                    </Link>
                                    <p className="mt-2 text-black" style={{ fontSize: '2.5rem', fontWeight: 'bold', fontFamily: 'Bebas Neue' }}>{store.name}</p>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
    
                {/* Image Slider */}
                <div className="slider-container mb-4" style={{ width: '100%', overflow: 'hidden' }}>
                    <Slider {...settings}>
                        {randomImages.map((image, index) => (
                            <div key={index} className="slider-item">
                                <img 
                                    src={image} 
                                    alt={`Random ${index}`} 
                                    className="img-fluid w-100" 
                                    style={{ height: "100vh", width: '80%', objectFit: "cover" }} 
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
    
                {/* Latest Products with Swiper */}
                <div className="Latest-Products" style={{ marginTop: '100px', marginLeft: 'auto', marginRight: 'auto', width: '90%' }}>
                    <h1 className="text-center text-black display-4" style={{ fontSize: '4rem', fontWeight: 'bold', marginTop: '50px', fontFamily: 'Bebas Neue', color: 'black' }}>Nos derniers produits</h1>
                    <div className="row justify-content-center m-4" style={{ height: '800px' }}>
    
                        <div style={{ marginBottom: '40px' }}></div>
                        <Swiper {...swiperSettings}>
                            {products.map((product) => {
                                const discount = product.discount || 0;
                                const originalPrice = product.price;
                                const discountedPrice = discount > 0 ? originalPrice - (originalPrice * (discount / 100)) : originalPrice;
    
                                return (
                                    <SwiperSlide key={product.id} className="mb-5" style={{ height: '550px', marginTop: '50px', width: '110%' }}>
                                        <div className="cards text-center" style={{ height: '90%', position: 'relative', width: '100%' }}>
                                            {discount > 0 && (
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    left: '10px',
                                                    backgroundColor: '#cf000f',
                                                    color: '#fff',
                                                    padding: '5px 10px',
                                                    borderRadius: '5px',
                                                    fontSize: '16px',
                                                    fontWeight: 'bold'
                                                }}>
                                                    -{discount}%
                                                </div>
                                            )}
                                            <Link to={`/product/${product.title}/${product.id}`}>
                                                <img src={product.image} alt={product.title} className="card-img-top img-fluid h-auto" />
                                            </Link>
                                            <div className="card-body">
                                                <h5 className="cards-title">
                                                    <Link className="text-dark text-decoration-none" to={`/product/${product.title}/${product.id}`}>
                                                        {product.title}
                                                    </Link>
                                                </h5>
                                                <h5 className="text" style={{ color: '#26619C' }}>
                                                    Price:
                                                    {discount > 0 ? (
                                                        <>
                                                            <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#26619C' }}>
                                                                {originalPrice} DT
                                                            </span>
                                                            <div style={{ color: '#cf000f', marginTop: '5px' }}>
                                                                {discountedPrice.toFixed(2)} DT
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span>{originalPrice} DT</span>
                                                    )}
                                                </h5>
                                            </div>
                                            <div className="card-footer" style={{ width: '100%', display: 'flex', justifyContent: 'center', color: 'white', marginBottom: '10px' }}>
                                                <Link className='button-31' style={{ fontSize: '20px', fontFamily: 'Oswald', display: 'flex', alignItems: 'center' }} to={`/product/${product.title}/${product.id}`}>
                                                    <i className="fa-solid fa-eye" style={{ marginRight: '8px' }}></i> Voir les détails
                                                </Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
    
                        <div className="prod-btn" style={{ width: '230px', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <Link className='button-30' to={'/Stores/1'} style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'Bebas Neue' }}>
                                <i className="fa-solid fa-arrow-down-a-z" style={{ marginRight: '8px' }}></i>
                                Tous les produits
                            </Link>
                        </div>
    
                        <div className="Image-Carousel" style={{ marginTop: '-10px' }}>
                            <h2 className="text-center my-4" style={{ fontSize: '4rem', fontWeight: 'bold', marginTop: '50px', fontFamily: 'Bebas Neue', color: 'black' }}>Nos marques</h2>
                            <Swiper {...{
                                spaceBetween: 30,
                                slidesPerView: 4,
                                loop: true,
                                autoplay: {
                                    delay: 3000,
                                    disableOnInteraction: false,
                                },
                                breakpoints: {
                                    640: { slidesPerView: 1 },
                                    768: { slidesPerView: 2 },
                                    1024: { slidesPerView: 4 },
                                },
                            }}>
                                {imageCarouselImages.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            src={image}
                                            alt={`Carousel Image ${index + 1}`}
                                            className="img-fluid"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
    
                    <div style={{ marginBottom: '450px' }}></div>
    
                </div>
            </main>   
        </div>
    
        {/* Messenger Bubble */}
        <div 
                        className="messenger-bubble" 
                        style={{
                            position: 'fixed',
                            bottom: '40px',
                            right: '20px',
                            width: '60px',    // Circular diameter
                            height: '60px',
                            backgroundImage: `url('/mess1.webp')`,  // Path to image in public folder
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            cursor: 'pointer',
                            borderRadius: '50%',  // Makes the bubble circular
                            backgroundColor: 'transparent', // Ensure no background color
                            border: 'none', // Remove any border if present
                            padding: '0', // Remove any padding if present
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                        }}
                        onClick={handleBubbleClick} // Link to Messenger conversation on click
                    />
                       
        </>
    );
    
}

export default Home;
