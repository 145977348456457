import React from 'react';
import './CrocoTeamStore.css'; // Make sure this path is correct

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';





function ProfilePage() {
    // Swiper settings for the profile page carousel
    const imageCarouselImages = [
        '/D1.jpg',
        '/D2.jpg',
        '/D3.jpg',
        '/D4.jpg',
        '/D5.jpg',
        '/D6.jpg',
        '/D7.jpg',
        '/D8.jpg',
    ];

    return (
        <div>
            <h2 className="numbering" style={{ color: '#2ecc71' }}>01 /</h2>
            <h3 className="title" style={{ fontSize: '40px' }}>PROGRAMME D'ENTRAÎNEMENT PERSONNALISÉ</h3>
            <p className="description" style={{ fontSize: '25px' }}></p>
            <section
    style={{
        fontFamily: 'Roboto, sans-serif',
        marginTop: '-60px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%', // Adjusted for better responsiveness
        display: 'flex', // Added flexbox for alignment
        alignItems: 'flex-start', // Align title and image to the same level
        gap: '30px', // Space between text and image
        flexWrap: 'wrap', // Ensure it wraps on smaller screens
    }}
>
    <div className="image-section" style={{ flex: '1', minWidth: '300px' }}>
        <img
            src="/D45.JPG"
            alt="Essan NFC"
            style={{
                border: '1px solid #009F6B', // Green border with color #009F6B
                borderRadius: '15px',        // Rounded corners
                width: '100%',               // Image spans full available space
                height: 'auto',
                marginTop: '30px',
            }}
        />
    </div>
    <div className="text-section" style={{ flex: '2', minWidth: '300px' }}>
        <h1 style={{ color: '#2ecc71', fontSize: '2rem', marginBottom: '10px' }}>Azer Mhamdi</h1>
        <h2 style={{ color: '#2ecc71', fontSize: '1.5rem', marginBottom: '20px' }}>
            Fondateur de NFC & Préparateur physique
        </h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '15px' }}>
            Je suis Azer Mhamdi, 🌎 International Prep Coach & Lifestyle Expert 💪🏻, juriste de formation, mais avant tout un passionné de culturisme et de coaching sportif. Depuis 2014, j’ai remporté 63 médailles 🥇 lors de compétitions nationales et internationales et accompagné avec succès plus de 450 transformations physiques 💪🏻.
        </p>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '15px' }}>
            Fondateur et CEO de @croco_team1 , @croconutrition, @crocoweartn, et @croco.market_, je m’efforce de faire évoluer le coaching sportif avec des programmes personnalisés, un suivi rigoureux et des services de qualité.
        </p>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '15px' }}>
            Comment puis-je vous aider ? Mon rôle est de vous accompagner dans votre transformation physique et votre bien-être, qu’il s’agisse de préparer une compétition ou simplement d’adopter un mode de vie plus sain. Grâce à des programmes sur mesure adaptés à vos besoins, je vous propose un suivi personnalisé qui inclut des plans d’entraînement, des démonstrations vidéo, et des recommandations nutritionnelles.
        </p>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8' }}>
            Mon objectif : inspirer et motiver mes adhérents à repousser leurs limites, progresser et atteindre leurs objectifs. En combinant expertise sportive et accompagnement sur mesure, je contribue à transformer des vies, que ce soit en salle, en ligne ou à travers mes marques dédiées au bien-être et à la performance.
        </p>
    </div>
</section>

            <div style={{ marginBottom: '-90px' }}></div>

            <section
    style={{
        fontFamily: 'Roboto, sans-serif',
        marginTop: '-40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '30px',
        flexWrap: 'wrap',
    }}
>
<div className="text-section" style={{ flex: '2', minWidth: '450px' }}>
    <h1 style={{ color: '#2ecc71', fontSize: '2rem', marginBottom: '10px' }}>Pourquoi me choisir</h1>
    
    <ul style={{ color: 'white', fontSize: '1.2rem', lineHeight: '1.8', listStyle: 'none', padding: '0' }}>
        <li style={{ marginBottom: '15px' }}>
            🌟 <strong>Expérience reconnue :</strong> Plus de 10 ans de passion et d’expertise dans le coaching sportif et le culturisme.
        </li>
        <li style={{ marginBottom: '15px' }}>
            🥇 <strong>Palmarès impressionnant :</strong> 63 médailles remportées lors de compétitions nationales et internationales.
        </li>
        <li style={{ marginBottom: '15px' }}>
            💪🏻 <strong>Résultats garantis :</strong> Plus de 450 transformations physiques réussies grâce à des programmes personnalisés.
        </li>
        <li style={{ marginBottom: '15px' }}>
            🧑‍💻 <strong>Suivi personnalisé :</strong> Un accompagnement adapté à vos objectifs avec un programme sur mesure, des démonstrations vidéo et des conseils précis.
        </li>
        <li style={{ marginBottom: '15px' }}>
            🌎 <strong>International Prep Coach :</strong> Une vision globale et moderne du coaching, inspirée des meilleures pratiques à l’étranger.
        </li>
        <li style={{ marginBottom: '15px' }}>
            🛠️ <strong>Marques dédiées à votre performance :</strong> Accès exclusif à mes produits et services via @croco_team1, @croconutrition, @crocoweartn, et @croco.market_.
        </li>
        <li>
            🤝 <strong>Relation humaine :</strong> Une approche motivante et bienveillante pour vous pousser à dépasser vos limites tout en vous sentant soutenu.
        </li>
    </ul>
</div>

    <div className="image-section" style={{ flex: '1', minWidth: '350px' }}>
        <img
            src="/DC1.jpg"
            alt="Pourquoi me choisir"
            style={{
                border: '1px solid #009F6B',
                borderRadius: '15px',
                width: '100%',
                height: 'auto',
                marginTop: '30px',
            }}
        />
    </div>
</section>



            <div style={{ marginBottom: '200px' }}></div>

            <div className="Image-Carousel" style={{ marginTop: '-100px' }}>
                <Swiper
                    spaceBetween={10} // Slight gap between slides
                    slidesPerView={4} // Display 4 images at a time
                    loop={true}
                    autoplay={{
                        delay: 10, // Change image every 0.09 seconds
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: { slidesPerView: 1 },  // 1 image for mobile
                        768: { slidesPerView: 2 },  // 2 images for tablet
                        1024: { slidesPerView: 4 }, // 4 images for desktop
                    }}
                >
                    {imageCarouselImages.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={image}
                                alt={`Carousel Image ${index + 1}`}
                                className="img-fluid"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    border: '1px solid #009F6B',
                                    borderRadius: '15px',
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        </div>
    );
}

export default ProfilePage;

