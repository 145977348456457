import React, { useEffect, useState } from 'react';
import './BackgroundAnimation.css';

const ImageGallery = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch('http://localhost:8000/images/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => setImages(data))
            .catch((error) => console.error("Fetching error:", error));
    }, []);

    return (
        
        <div  className="black-bg"
            style={{ 
                marginTop: '250px',
                padding: '0 140px', // Add padding to the sides
                display: 'grid', 
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '20px' // Add spacing between images
            }}
        >
            
            {images.map((img, index) => (
                <div key={index} style={{ textAlign: 'center', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden', transition: 'transform 0.2s' }}>
                    <img 
                        src={img.image_url} 
                        alt={img.title} 
                        style={{ width: '85%', height: '320px', objectFit: 'cover' }} // Uniform image height
                        onError={(e) => e.target.style.display = 'none'} // Hide if image fails to load
                    />
                    <div style={{ padding: '10px' }}>
                        <h3 style={{ fontSize: '1.2rem', margin: '10px 0' }}>{img.title}</h3>
                        <p style={{ fontSize: '0.9rem', color: '#666' }}>{img.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImageGallery;
