import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import OneProduct from './OneProduct';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function SubcategoryProducts() {
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const { subcategoryId } = useParams();
    const location = useLocation();

    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchData();
    }, [subcategoryId, currentPage]);

    function fetchData() {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get('page') || 1;
        setCurrentPage(page);

        let url = `${baseUrl}Subcategory/${subcategoryId}/products/?page=${page}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setProducts(data.results);
                setTotalPages(Math.ceil(data.count / 10)); // Assuming 10 products per page
            })
            .catch((error) => console.error('Error fetching products:', error));
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        const url = new URL(window.location);
        url.searchParams.set('page', pageNumber);
        window.history.pushState({}, '', url);
    };

    return (
        <div style={{ marginTop: '250px' }}>
            {/* Title Section */}
            <h1 className="text-center text-black mb-5 fw-bold" style={{ fontFamily: 'Oswald', fontSize: '45px' }}>
                Les produits existants
            </h1>

            {/* Product Listings */}
            <div className="products" style={{ width: '60%', margin: '0 auto' }}>
                <div className="row row-cols-2 row-cols-md-4 g-4">
                    {products.map((product) => (
                        <OneProduct key={product.id} product={product} />
                    ))}
                </div>

                {/* Pagination */}
                <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center mt-4" style={{ gap: '10px' }}>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(index + 1)}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '50%',
                                        border: '2px solid #000', // Adjust the border color as needed
                                        backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                        color: currentPage === index + 1 ? '#fff' : '#000',
                                        display: 'flex',
                                        fontFamily: 'Oswald',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default SubcategoryProducts;
