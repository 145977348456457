import React, { useEffect, useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CartContext } from './Context';
import './BackgroundAnimation.css';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

const CoachingGallery = () => {
    const { cartData, setCartData } = useContext(CartContext);
    const [coachingItems, setCoachingItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const location = useLocation();
   // const baseUrl = 'http://localhost:8000/';
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    useEffect(() => {
        fetchCoachingItems();
    }, [currentPage]);

    const fetchCoachingItems = () => {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get('page') || 1;
        setCurrentPage(page);

        fetch(`${baseUrl}coaching/?page=${page}`)
            .then((response) => response.json())
            .then((data) => {
                setCoachingItems(Array.isArray(data) ? data : []);
                setTotalPages(Math.ceil(data.length / 10));
            })
            .catch((error) => console.error("Fetching error:", error));
    };

    const cartAddButtonHandler = (coaching) => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: coaching.id,
                title: coaching.title,
                price: coaching.price,
                image: coaching.image_url,
                color: coaching.color,
                size: coaching.size,
            },
            quantity: 1,
            user: { id: 1 },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <section className="container coaching-page" style={{ marginTop: '250px', marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
           
           
            <h1 className="text-center text-black mb-5 fw-bold" style={{ fontFamily: 'Bebas Neue', fontSize: '55px', fontWeight: 'bolder' }}>
                Nos offres de Coaching
            </h1>
    
            <div className="row" style={{ marginLeft: '-70px', marginRight: '-70px' }}>
    {coachingItems.map((coaching) => {
        const originalPrice = coaching.price;
        const discountedPrice = (originalPrice * (1 - coaching.discount / 100)).toFixed(2);

        return (
            <div className="col-md-4 col-sm-6 col-xs-12 mb-4" key={coaching.id} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="card" style={{ height: '700px', width: '100%', position: 'relative' }}>
                {coaching.discount > 0 && (
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: '#cf000f',
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }}>
                        -{Math.floor(coaching.discount)}%
                    </div>
                )}
                    <img src={coaching.image_url} alt={coaching.title} style={{ marginTop: '15px', width: '100%', height: '220px', objectFit: 'contain' }} />

                    <div className="card-body-product d-flex flex-column align-items-center justify-content-between" style={{ padding: '8px', fontFamily: 'Impact', fontSize: '18px', color: 'black', textDecoration: 'none', flexGrow: 1 }}>
                        <h5 className="card-title">
                            <Link style={{ fontFamily: 'Roboto', fontWeight: 'bolder', color: 'black', textDecoration: 'none', fontSize: '22px' }} to={`/product/${coaching.slug}/${coaching.id}`}>
                                {coaching.title}
                            </Link>
                        </h5>

                        <ul style={{ listStyleType: 'none', padding: '0', fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '17px' }}>
                            {(coaching.description || "").split('\n').map((line, index) => (
                                <li key={index}>
                                    <span style={{ fontSize: '5px' }}>⚫</span> {line}
                                </li>
                            ))}
                        </ul>

                        <h5 className="card-title" style={{ fontFamily: 'Roboto', marginBottom: '20px', fontSize: '20px', color: '#26619C' }}>
                            Prix:
                            {coaching.discount > 0 ? (
                                <>
                                    <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#26619C' }}>
                                        {originalPrice} DT
                                    </span>
                                    <span style={{ color: '#cf000f' }}>
                                        {discountedPrice} DT
                                    </span>
                                </>
                            ) : (
                                <span>{originalPrice} DT</span>
                            )}
                        </h5>
                    </div>

                    <div className="card-footer-product d-flex flex-column align-items-center" style={{ marginBottom: '17px' }}>
                        <button
                            onClick={() => cartAddButtonHandler(coaching)}
                            style={{
                                padding: '8px 20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '30px',
                                backgroundColor: '#2ecc71',
                                color: '#fff',
                                border: 'none',
                                fontFamily: 'Roboto',
                                fontSize: '17px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                width: '70%',
                                height: '50px'
                            }}
                        >
                            <i className="fa-solid fa-cart-plus" style={{ marginRight: '8px' }}></i>Ajouter au panier
                        </button>
                    </div>
                </div>
            </div>
        );
    })}
</div>

    
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center mt-4" style={{ gap: '10px' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    border: '2px solid #000',
                                    backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                    color: currentPage === index + 1 ? '#fff' : '#000',
                                    display: 'flex',
                                    fontFamily: 'Oswald',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '1.2rem',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Paragraph Section */}
            <div className="coaching-item">
                <h2 className="numbering" style={{ color: '#2ecc71'}}>01 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>PROGRAMME D'ENTRAÎNEMENT PERSONNALISÉ</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    Nous ne sommes pas tous égaux face à la nature. C'est le fait de la génétique. La 1ère différence se situe déjà au niveau du genre.
                    Il serait donc incohérent qu'un même programme soit effectué par tous, ne serait-ce que du point de vue anatomique.
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    Partant de ce postulat, il devient évident que chaque profil doit bénéficier d'un encadrement afin de mettre l'accent sur sa singularité physique
                    (taille, poids, sexe, longueur des membres, insertions musculaires, emploi du temps, etc...).
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    Le but d'un plan d'entraînement personnalisé est de prendre en compte toutes ces spécificités pour magnifier vos résultats physiques.
                </p>
            </div>
            
            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>02 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>PLAN DIÈTE PERSONNALISÉE</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    La diète, dans toute activité sportive, se doit d'être en absolue cohérence avec la discipline pratiquée.
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    Qu'est-ce qu'une diète adaptée ?
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    C'est le fait de privilégier certains aliments par rapport à d'autres. L'intérêt est d'apporter à votre corps l'ensemble des nutriments nécessaires
                    à sa construction et dans les quantités exactes. Cela revient donc à fournir les macros les mieux adaptés au volume d'entraînement fourni et à l'objectif souhaité.
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>03 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>COMPLÉMENTS ALIMENTAIRES ADAPTÉS</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    Avant tout, il est essentiel de préciser que la consommation de compléments alimentaires ne doit en aucun cas remplacer une bonne alimentation.
                    Le corps humain produit un certain nombre de minéraux, vitamines et nutriments nécessaires à son fonctionnement. La plupart de ces nutriments
                    sont également présents dans notre alimentation. Cependant, ils peuvent être produits ou disponibles dans des proportions insuffisantes en fonction du mode de vie que l'on adopte.
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    En effet, un individu avec une forte ou intense activité physique va nécessiter un besoin en nutriments bien plus conséquent qu'une personne dont
                    l'activité physique se résume à 1 voire 2 séances par semaine. Il va donc devenir intéressant pour toute personne, à volume d'entraînement élevé, d'apporter une complémentarité à son alimentation.
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    L'un des plus connus de ces compléments alimentaires est la protéine de lait mais il en existe bien d'autres. Ils viendront équilibrer la balance
                    de nos besoins qui peut être perturbée par nos différents rythmes de vie. Sur mon site, dans l'onglet F.A.Q, vous trouverez une collection de vidéos explicatives qui détaillent chaque complément alimentaire et son utilisation.
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>04 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>CHEATMEAL COMPRIS DANS LA DIÈTE</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    La diète que vous suivez durant votre coaching est calculée et est en lien avec votre entraînement. Ce qui signifie que certains aliments en sont exclus.
                    Cependant, et afin de conserver votre motivation intacte, relancer le metabolisme pour certains cas, un "repas plaisir" vous est permis, en concertation avec moi afin d'en déterminer la qualité mais également le moment.
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>05 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>BILAN TOUTES LES 2 SEMAINES</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    Chaque personne est différente. Que ce soit au niveau physique ou psychologique. En choisissant un pack avec suivi, vous vous assurez ma disponibilité,
                    à la fois pour répondre à vos questions mais aussi sur le plan du mental et de la motivation. N'importe quelle bonne résolution peut se trouver perturbée par un changement de situation personnelle.
                </p>
                <p className="description"style={{ fontSize: '25px'}}>
                    Nos échanges réguliers me permettront de mieux vous connaître, et de répondre de manière efficace à vos besoins ou attentes. Cela pourra concerner le choix
                    d'un exercice plutôt qu'un autre parce que le 1er n'est pas ou peu adapté à votre morphologie ou encore, apporter des modifications rapides à votre diète, en fonction de son impact sur vous.
                    J'accorde une grande importance à l'accompagnement car c'est la clé de votre accomplissement. Nous allons travailler de façon concrète, mettons toutes les chances de notre côté.
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>06 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>AJUSTEMENT SELON BILAN</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    La diète et l'entraînement personnalisés que vous suivrez auront un impact sur votre corps. Les ajustements que j'apporterai ont pour but que cet impact évolue dans le sens souhaité.
                    Cela pourra concerner des méthodes de travail ou alors le fait de revoir à la hausse ou à la baisse les quantités de macros nutriments consommés.
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>07 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>RÉPONSE PAR E-MAIL SOUS 24H</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                    Je m'engage à répondre sous 24h à vos mails afin que vous puissiez poursuivre rapidement votre entraînement (hors weekends et jours fériés.)
                </p>
            </div>

            <div className="coaching-item">
                <h2 className="numbering"  style={{ color: '#2ecc71'}} >08 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>cas de maladie-opération médicale</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                   Pause en cas de maladie-opération médicale ✅
                ‎⚠️لا يمكن إيقاف البرنامج والرجوع لأي سبب كان ما عدا المرض
                </p>
            </div>
            <div className="coaching-item">
                <h2 className="numbering"style={{ color: '#2ecc71'}}>09 /</h2>
                <h3 className="title"style={{ fontSize: '40px'}}>PED</h3>
                <p className="description"style={{ fontSize: '25px'}}>
                     PED pour les athletes ( +100dt )
                </p>
            </div>
          
        </section>
    );
};

export default CoachingGallery;
