import React, { useState, useEffect, useContext } from 'react';
import './ProductDetail.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import OneRelatedProduct from './OneRelatedProduct';
import { UserContext, CartContext } from './Context';
import axios from 'axios';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant


function ProductDetail() {
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const [productData, setProductData] = useState({});
    const [productImgs, setProductImgs] = useState([]);
    const [productTags, setProductTags] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const { Product_id } = useParams();
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const { cartData, setCartData } = useContext(CartContext);
    const userContext = useContext(UserContext);
    const [wishlistMessage, setWishlistMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        fetchData(baseUrl + 'Product/' + Product_id + '/');
        fetchRelatedData(baseUrl + 'Related-Products/' + Product_id + '/');
        checkProductInCart(parseInt(Product_id));
    }, [Product_id]);

    useEffect(() => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart);
        if (cartJson != null) {
            const isInCart = cartJson.some(cart => cart.product.id === parseInt(Product_id));
            setCartButtonClickStatus(isInCart);
        }
    }, [Product_id]);

    function checkProductInCart(product_id) {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart);
        if (cartJson != null) {
            cartJson.forEach((cart) => {
                if (cart != null && cart.product.id === product_id) {
                    setCartButtonClickStatus(true);
                }
            });
        }
    }

    async function fetchData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Product Data:', data); // Debug log
            setProductData(data);
            setProductImgs(data.product_imgs || []);
            setProductTags(data.tag_list || []);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    async function fetchRelatedData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Related Products:', data); // Debug log
            setRelatedProducts(data.results || []);
        } catch (error) {
            console.error('Error fetching related products:', error);
        }
    }

    const tagsLinks = productTags.map((tag, index) => (
        <Link key={index} className='badge bg-secondary text-white me-1' to={`/products/${tag.trim()}`}>
            {tag.trim()}
        </Link>
    ));

    const cartAddButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: productData.id,
                title: productData.title,
                price: productData.price,
                image: productData.image,
            },
            quantity: selectedQuantity,
            user: {
                id: 1, // Assuming user ID is hardcoded or fetched from context
            },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(true);
    };

    const cartRemoveButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const updatedCartJson = cartJson.filter(cart => cart.product.id !== productData.id);
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(false);
    };

    function saveInWishList() {
        const buyerId = localStorage.getItem('buyer_id'); // Adjust according to your actual key

        if (!buyerId) {
            console.error('Buyer ID not found in localStorage');
            return;
        }

        const formData = new FormData();
        formData.append('Buyer', buyerId);
        formData.append('product', productData.id);

        axios
            .post(baseUrl + 'Wishlist/', formData)
            .then(function (response) {
                setWishlistMessage(
                    <div style={{ fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center', maxWidth: '300px', margin: '0 auto' }}>
                        Product added to wishlist successfully.
                    </div>
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const { discount } = productData;

    const openDemoPage = () => {
        if (productData.demo_url) {
            window.open(productData.demo_url, '_blank');
        } else {
            alert('No demo link available for this product.');
        }
    };

    const [isZoomedProduct, setIsZoomedProduct] = useState(false);
    const [zoomPositionProduct, setZoomPositionProduct] = useState({ x: 50, y: 50 });
    
    // State for the description image zoom
    const [isZoomedDescription, setIsZoomedDescription] = useState(false);
    const [zoomPositionDescription, setZoomPositionDescription] = useState({ x: 50, y: 50 });
    
    // Mouse move handler for product image
    const handleMouseMoveProduct = (e, index) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPositionProduct({ x, y });
        setIsZoomedProduct(true);
    };
    
    // Mouse leave handler for product image
    const handleMouseLeaveProduct = () => {
        setIsZoomedProduct(false);
    };
    
    // Mouse move handler for description image
    const handleMouseMoveDescription = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPositionDescription({ x, y });
        setIsZoomedDescription(true);
    };
    
    // Mouse leave handler for description image
    const handleMouseLeaveDescription = () => {
        setIsZoomedDescription(false);
    };



    const getFlavorColor = (flavor) => {
        switch (flavor) {
            case 'chocolat':
                return '#8B4513'; // Brown
            case 'vanille':
                return '#FFFFFF'; // White
            case 'fraise':
                return '#FFC0CB'; // Pink
            case 'cookies':
                return '#F5F5DC'; // Beige
            case 'fraise_banane':
                return '#f7c177'; // Semi pink semi yellow
            case 'caramel':
                return '#D2B48C'; // Wheat
            default:
                return '#000'; // Default color (black)
        }
    };

    const flavors = productData.flavour ? productData.flavour.split(',') : [];
    
    return (
        <section className="container mt-5" style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
            <div className="row" style={{ marginTop: '250px' }}>
    <div className="col-12 col-md-4 d-flex justify-content-center mb-4">
        <div id="productThumbnailSlider" className="carousel carousel-dark slide carousel-fade" data-bs-ride="true" style={{ maxWidth: "100%", width: "400px" }}>

            <div className="carousel-inner">
                {productImgs.map((img, index) => (
                    <div
                        key={index}
                        className={index === 0 ? "carousel-item active" : "carousel-item"}
                        style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <div
                            className="img-container"
                            onMouseMove={(e) => handleMouseMoveProduct(e, index)}
                            onMouseLeave={handleMouseLeaveProduct}
                            style={{ position: 'relative' }}
                        >
                            <img
                                src={img.image}
                                className="img-thumbnail mb-5"
                                alt={`Slide ${index + 1}`}
                                style={{ maxWidth: "110%", height: "auto"}}
                            />
                            {isZoomedProduct && (
                                <div
                                    className="zoom-overlay"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: `url(${img.image})`,
                                        backgroundPosition: `${zoomPositionProduct.x}% ${zoomPositionProduct.y}%`,
                                        backgroundSize: '300% 300%',
                                        zIndex: 100,
                                        pointerEvents: 'none',
                                        borderRadius: '10px',
                                        border: '1px solid #fff',
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#productThumbnailSlider" data-bs-slide="prev" style={{ width: '260px', height: '430px' }}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#productThumbnailSlider" data-bs-slide="next" style={{ width: '180px', height: '430px' }}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>

        </div>
    </div>
    
    <div className="col-12 col-md-8">
        <div style={{ marginLeft: '20px', padding: '0 15px' }}>
            <h3 style={{ textAlign:'center',fontFamily: 'Oswald', fontSize: '45px', color: 'black', letterSpacing: '1.5px', fontWeight: 'bolder', textTransform: 'uppercase' }}>{productData.title}</h3>
            <div style={{ marginBottom: '40px' }}></div>
            <p style={{ fontFamily: 'Oswald', fontSize: '22px', color: '#555555', fontWeight: 'bolder' }}>{productData.detail}</p>
            <div style={{ marginBottom: '30px' }}></div>
            <h3 style={{ fontFamily: 'Oswald', fontSize: '33px', color: 'black', letterSpacing: '1.5px', fontWeight: 'bolder', display: 'inline-block' }}>
                Prix: 
                {discount > 0 ? (
                    <>
                        <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#888' }}>{productData.price}DT</span>
                        <span style={{ color: '#cf000f' }}>
                            {(productData.price - (productData.price * discount / 100)).toFixed(2)}DT
                        </span>
                    </>
                ) : (
                    <span>{productData.price}DT</span>
                )}
            </h3>

            <div className="d-flex flex-column flex-md-row align-items-center gap-3 mt-4">
                <button 
                    className="btn"
                    style={{ fontFamily: 'Oswald', fontSize: '20px', padding: '15px 35px', fontWeight:'500', borderRadius: '5px', backgroundColor: '#2ecc71',textTransform:'uppercase' }}
                    onClick={cartAddButtonHandler}
                >
                    <i className="fa-solid fa-cart-plus"></i> Ajouter au panier
                </button>
                {cartButtonClickStatus && (
                    <button 
                        className="btn"
                        style={{ fontFamily: 'Oswald', fontSize: '20px', padding: '15px 35px', borderRadius: '5px', backgroundColor: '#DC343B' }}
                        onClick={cartRemoveButtonHandler}
                    >
                        <i className="fa-solid fa-circle-minus"></i> Supprimer du panier
                    </button>
                )}
            </div>

            <div className="d-flex flex-column flex-md-row mt-3 gap-3">
                <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                    Quantité: {productData.quantity}
                </p>
                <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                    <i className="fa fa-calendar" aria-hidden="true"></i> Date d'expiration: {new Date(productData.expiration_date).toLocaleDateString('en-GB')}
                </p>
            </div>


            {flavors.length > 0 && (
                <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                    Saveurs: 
                    {flavors.map((flavor, index) => (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                            <span style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: getFlavorColor(flavor.trim()), marginRight: '5px' }}></span>
                            {flavor.trim()}
                        </span>
                    ))}
                </p>
            )}

            <div style={{ marginTop: '30px', backgroundColor: '#A9BA9D', padding: '20px', borderRadius: '5px' }}>
                {productData.description && (
                    <div style={{ textAlign: 'left' }}>
                        <h2>Description:</h2>
                        <p style={{ fontFamily: 'Roboto', fontSize: '16px', color: 'white', lineHeight: '1.6' }}>{productData.description}</p>
                    </div>
                )}

                {productData.description_image_1 && (
                    <div className="d-flex justify-content-center mt-4">
                        <img
                            src={productData.description_image_1}
                            className="img-thumbnail"
                            alt="Description 1"
                            style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                            onMouseMove={handleMouseMoveDescription}
                            onMouseLeave={handleMouseLeaveDescription}
                        />
                        {isZoomedDescription && (
                            <div
                                className="zoom-overlay"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '500px',
                                    height: '500px',
                                    backgroundImage: `url(${productData.description_image_1})`,
                                    backgroundPosition: `${zoomPositionDescription.x}% ${zoomPositionDescription.y}%`,
                                    backgroundSize: '300% 300%',
                                    zIndex: 100,
                                    pointerEvents: 'none',
                                    borderRadius: '10px',
                                    border: '1px solid #fff',
                                }}
                            ></div>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>
</div>


{wishlistMessage && (
<div className="alert alert-success" role="alert" style={{ padding: '15px 16px', margin: '10px auto', maxWidth: '400px', textAlign: 'center', borderRadius: '5px', fontSize: '14px' }}>
{wishlistMessage}
</div>
)}

<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
    {/* Carousel Indicators */}
    <div className="carousel-indicators">
        {relatedProducts.reduce((acc, _, index) => {
            if (index % 4 === 0) {
                acc.push([]);
            }
            acc[acc.length - 1].push(index);
            return acc;
        }, []).map((group, groupIndex) => (
            <button
                key={groupIndex}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={groupIndex}
                className={groupIndex === 0 ? "active" : ""}
                aria-current={groupIndex === 0 ? "true" : "false"}
                aria-label={`Slide ${groupIndex + 1}`}
            />
        ))}
    </div>

    {/* Carousel Inner */}
    <div className="carousel-inner" style={{ marginTop: '80px' }}>
        <h1 className="text-center text-black display-4" style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '40px' }}>
            Related Products
        </h1>
        {relatedProducts.reduce((acc, product, index) => {
            const discount = product.discount || 0; // Assuming you have a discount property
            const originalPrice = product.price; // Original price
            const discountedPrice = discount > 0 ? originalPrice - (originalPrice * (discount / 100)) : originalPrice; // Calculate discounted price

            if (index % 4 === 0) {
                acc.push([]);
            }
            acc[acc.length - 1].push(
                <div className="col" key={index} style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
                    <div style={{ width: '300px', height: '550px', margin: '0px' }}>
                        <div className="cards text-center" style={{ height: '90%', position: 'relative' }}>
                            {discount > 0 && (
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    backgroundColor: '#cf000f',
                                    color: '#fff',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    fontWeight: 'bold'
                                }}>
                                    -{discount}%
                                </div>
                            )}
                            <Link to={`/product/${product.title}/${product.id}`}>
                                <img src={product.image} alt={product.title} className="card-img-top img-fluid h-auto" />
                            </Link>
                            <div className="card-body">
                                <h5 className="cards-title">
                                    <Link className="text-dark text-decoration-none" to={`/product/${product.title}/${product.id}`}>
                                        {product.title}
                                    </Link>
                                </h5>
                                <h5 className="text" style={{ color: '#26619C' }}>
                                    Price:
                                    {discount > 0 ? (
                                        <>
                                            <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#26619C' }}>
                                                {originalPrice} DT
                                            </span>
                                            <div style={{ color: '#cf000f', marginTop: '5px' }}>
                                                {discountedPrice.toFixed(2)} DT
                                            </div>
                                        </>
                                    ) : (
                                        <span>{originalPrice} DT</span>
                                    )}
                                </h5>
                            </div>
                            <div className="card-footer" style={{ width: '100%', display: 'flex', justifyContent: 'center', color: 'white', marginBottom: '10px' }}>
                                <Link className='button-31' to={`/product/${product.title}/${product.id}`}>
                                    Voir les détails
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
            return acc;
        }, []).map((productGroup, groupIndex) => (
            <div className={groupIndex === 0 ? "carousel-item active" : "carousel-item"} key={groupIndex}>
                <div className="row justify-content-center" style={{ display: 'flex', justifyContent: 'center' }}>
                    {productGroup}
                </div>
            </div>
        ))}
    </div>

    {/* Carousel Controls */}
    <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
        style={{ marginTop: '-50px', color: 'black' }}
    >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
    </button>
    <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
        style={{ marginTop: '-50px', color: 'black' }}
    >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
    </button>
</div>


<div style={{ marginBottom: '120px' }}></div>
</section>
);
}

export default ProductDetail;
