import React from 'react';
import './MovingBanner.css';

const MovingBanner = () => {
    return (
        <div className="moving-banner">
            <div className="banner-content">
                <p style={{ fontSize: '15px', letterSpacing: '2px', fontFamily: 'Oswald' }} className="moving-text centered-text">
                    BIENVENUE À CROCO LAND | REJOIGNEZ-NOUS MAINTENANT ET VIVEZ VOTRE EXPÉRIENCE CROCO | 
                     LIVRAISON GRATUITE A PARTIR DE 100 DT <i className="fas fa-shipping-fast" style={{ color: '#009F6B' }}></i>
                </p>
            </div>
        </div>

    );
}

export default MovingBanner;
